import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@mui/material';
import { InputSelect } from '@aldridge/aldg-data-components';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import VoucherSummary from './components/VoucherSummary';
import SuccessStats from './components/SuccessStats';
import FailStats from './components/FailStats';

import TabPanel from '../../../_GlobalComponents/TabPanel';

dayjs.extend(utc);

const History = (props) => {
  const {
    ExportButtons,
    tval,
    exportHistorySuccessFunctionCall,
    exportHistoryFailFunctionCall
  } = props;
  const [Type] = useState('Invoices');
  const [Date, setDate] = useState('');

  dayjs.extend(utc);

  // get all dates cloud function
  const {
    data: allDates,
    loading: datesLoading,
    error: datesError,
    functionCall: getAllDatesFunctionCall
  } = useFunctionCall('getAllDates');
  // checks date and gets voucher info
  const {
    data: voucherData,
    loading: voucherLoading,
    error: voucherError,
    functionCall: voucherFunctionCall
  } = useFunctionCall('voucherNumbers');
  // gets success stats for date record pull
  const {
    data: successStatsData,
    loading: successStatsLoading,
    error: successStatsError,
    functionCall: successStatsFunctionCall
  } = useFunctionCall('successStats');
  // gets fail stats for date record pull
  const {
    data: failStatsData,
    loading: failStatsLoading,
    error: failStatsError,
    functionCall: failStatsFunctionCall
  } = useFunctionCall('failStats');

  const onChange = (event) => {
    try {
      setDate(event.target.value);
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    setDate('');
    getAllDatesFunctionCall({ path: Type });
  }, [Type]);

  useEffect(() => {
    if (Date !== '') {
      voucherFunctionCall({
        path: Type,
        VoucherDate: Date
      });
      successStatsFunctionCall({
        path: Type,
        SuccessDate: Date
      });
      failStatsFunctionCall({
        path: Type,
        FailDate: Date
      });
    }
  }, [Date]);

  useEffect(() => {
    if (!datesLoading && typeof datesError !== 'undefined') {
      toast.error(datesError);
    }
    if (!voucherLoading && typeof voucherError !== 'undefined') {
      toast.error(voucherError);
    }
    if (!successStatsLoading && typeof successStatsError !== 'undefined') {
      toast.error(successStatsError);
    }
    if (!failStatsLoading && typeof failStatsError !== 'undefined') {
      toast.error(failStatsError);
    }
  }, [
    datesError,
    voucherError,
    successStatsError,
    failStatsError,
    datesLoading,
    voucherLoading,
    successStatsLoading,
    failStatsLoading
  ]);

  return (
    <TabPanel value={tval} index={1}>
      <Paper variant='outlined' style={{ padding: '8px' }}>
        <Grid container>
          <Grid item xs={12} sx={{ alignContent: 'center' }}>
            <InputSelect
              label='Date Select'
              id='Date'
              name='Date'
              options={(allDates?.recordset || []).map((dateObj) => {
                // console.log(dateObj.DATE, dayjs(dateObj.DATE), dayjs());
                const [dt, tm] = dateObj.DATE.split('T');
                const [hr, min] = tm.split(':');
                let selectedDate = dayjs(dt);
                selectedDate = selectedDate
                  .hour(parseInt(hr, 10))
                  .minute(parseInt(min, 10));
                const NewDateArray = {
                  value: dateObj.DATE,
                  label: selectedDate.format('MM/DD/YYYY hh:mm A'),
                  key: dateObj.DATE
                };
                NewDateArray[dateObj.key] = dateObj.value;
                return NewDateArray;
              })}
              onChange={onChange}
              value={Date}
              loading={datesLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ExportButtons
              date={Date}
              Type={Type}
              successStats={successStatsData?.recordset}
              failStats={failStatsData?.recordset}
              vouchers={voucherData?.recordset || []}
            />
          </Grid>
          <Grid item xs={12}>
            <SuccessStats
              successRecord={successStatsData?.recordset || []}
              successLoading={successStatsLoading}
              date={Date}
              Type={Type}
              exportHistorySuccessFunctionCall={
                exportHistorySuccessFunctionCall
              }
            />
          </Grid>
          {Type !== 'PurchaseOrders' && (
            <Grid item xs={12}>
              <VoucherSummary
                voucherRecord={voucherData?.recordset || []}
                voucherLoading={voucherLoading}
                date={Date}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FailStats
              failRecord={failStatsData?.recordset || []}
              voucherLoading={failStatsLoading}
              date={Date}
              Type={Type}
              exportHistoryFailFunctionCall={exportHistoryFailFunctionCall}
            />
          </Grid>
        </Grid>
      </Paper>
    </TabPanel>
  );
};

History.propTypes = {
  ExportButtons: PropTypes.func,
  tval: PropTypes.number,
  exportHistorySuccessFunctionCall: PropTypes.func,
  exportHistoryFailFunctionCall: PropTypes.func
};

History.defaultProps = {
  ExportButtons: () => {},
  tval: -1,
  exportHistorySuccessFunctionCall: () => {},
  exportHistoryFailFunctionCall: () => {}
};
export default History;
