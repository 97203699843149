import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Button } from '@mui/material';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import VoucherSummary from './components/VoucherSummary';
import SuccessStats from './components/SuccessStats';
import FailStats from './components/FailStats';

import TabPanel from '../../../_GlobalComponents/TabPanel';

const InvoiceProcessing = (props) => {
  const {
    ExportButtons,
    tval,
    exportHistorySuccessFunctionCall,
    exportHistoryFailFunctionCall
  } = props;

  const [Date, setDate] = useState('');
  const {
    data: processInvoicesData,
    loading: processInvoicesLoading,
    error: processInvoicesError,
    functionCall: processInvoicesFunctionCall
  } = useFunctionCall('processInvoices', undefined, 540000);
  const {
    data: voucherData,
    loading: voucherLoading,
    error: voucherError,
    functionCall: voucherFunctionCall
  } = useFunctionCall('voucherNumbers');
  const {
    data: successStatsData,
    loading: successStatsLoading,
    error: successStatsError,
    functionCall: successStatsFunctionCall
  } = useFunctionCall('successStats');
  const {
    data: failStatsData,
    loading: failStatsLoading,
    error: failStatsError,
    functionCall: failStatsFunctionCall
  } = useFunctionCall('failStats');

  useEffect(() => {
    if (!processInvoicesLoading && typeof processInvoicesData !== 'undefined') {
      toast.success('Processed Invoices');
      if (processInvoicesData.recordset?.length > 0) {
        setDate(processInvoicesData.recordset[0].DATE);
      }
    }
    if (
      !processInvoicesLoading &&
      typeof processInvoicesError !== 'undefined'
    ) {
      toast.error(processInvoicesError);
    }
  }, [processInvoicesData, processInvoicesLoading, processInvoicesError]);

  useEffect(() => {
    if (Date !== '') {
      voucherFunctionCall({
        path: 'Invoices',
        VoucherDate: Date
      });
      successStatsFunctionCall({
        path: 'Invoices',
        SuccessDate: Date
      });
      failStatsFunctionCall({
        path: 'Invoices',
        FailDate: Date
      });
    }
  }, [Date]);
  useEffect(() => {
    if (typeof voucherError !== 'undefined') toast.error(voucherError);
    if (typeof successStatsError !== 'undefined')
      toast.error(successStatsError);
    if (typeof failStatsError !== 'undefined') toast.error(failStatsError);
  }, [voucherError, successStatsError, failStatsError]);

  console.log(
    successStatsData?.recordset,
    voucherData?.recordset,
    failStatsData?.recordset
  );
  return (
    <TabPanel value={tval} index={0}>
      <Paper variant='outlined' style={{ padding: '8px' }}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ExportButtons
              date={Date}
              successStats={successStatsData?.recordset || []}
              vouchers={voucherData?.recordset || []}
              failStats={failStatsData?.recordset || []}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {processInvoicesLoading ? (
              <div
                style={{
                  display: 'flex',
                  position: 'absolute',
                  zIndex: 2600,
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: '#fff',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  opacity: '.70'
                }}
              >
                <Loader
                  type='TailSpin'
                  color='#3b78e7'
                  height={50}
                  width={50}
                />
                Processing...{' '}
              </div>
            ) : (
              <>
                <Button
                  variant='contained'
                  style={{ margin: '20px' }}
                  color='primary'
                  onClick={() =>
                    processInvoicesFunctionCall({ path: 'Invoices' })
                  }
                >
                  Process Invoices
                </Button>

                <SuccessStats
                  successRecord={successStatsData?.recordset || []}
                  successLoading={successStatsLoading}
                  date={Date}
                  exportHistorySuccessFunctionCall={
                    exportHistorySuccessFunctionCall
                  }
                />
                <VoucherSummary
                  voucherRecord={voucherData?.recordset || []}
                  voucherLoading={voucherLoading}
                  date={Date}
                />
                <FailStats
                  failRecord={failStatsData?.recordset || []}
                  failLoading={failStatsLoading}
                  date={Date}
                  exportHistoryFailFunctionCall={exportHistoryFailFunctionCall}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </TabPanel>
  );
};

InvoiceProcessing.propTypes = {
  ExportButtons: PropTypes.func,
  tval: PropTypes.number,
  exportHistorySuccessFunctionCall: PropTypes.func,
  exportHistoryFailFunctionCall: PropTypes.func
};

InvoiceProcessing.defaultProps = {
  ExportButtons: () => {},
  tval: -1,
  exportHistorySuccessFunctionCall: () => {},
  exportHistoryFailFunctionCall: () => {}
};
export default InvoiceProcessing;
